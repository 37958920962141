import React from 'react';
import Helmet from 'react-helmet';
import LandingHeader from '../../../components/Landing/Section/Header';
import HeroSection from '../../../components/Landing/Section/Hero';
import TestimonialSection from '../../../components/Landing/Section/Testimonial'; // Assuming you have a TestimonialSection component
import '../../../assets/css/landing.css';

import LifeprintImage from '../../../assets/images/landing/lifeprint/lifeprint-section-image.webp';
import AboutSection from '../../../components/Landing/Section/About';
import ClientLogosSection from '../../../components/Landing/Section/ClientLogos/lndex';
import LandingFooter from '../../../components/Landing/Section/Footer';
import PartnershipSection from '../../../components/Landing/Section/Partnership';

const themeColor = '#1AA5F8';

const Hero = {
  title: (
    <div>
      The portable photo printer, with AR & social networking app, that went on to become{' '}
      <strong>Apple’s retail partner.</strong>
    </div>
  ),
  bannerSrc: require('../../../assets/images/landing/lifeprint/lifeprint-banner.svg').default,
  logoSrc: require('../../../assets/images/landing/lifeprint/lifeprint-logo.svg').default,
};

const Testimonial = {
  details: [
    <div>
      Raised <span className="text-blue">$2.2M+</span> in funding
    </div>,
    <div>
      Became <span className="text-blue">Apple</span> Global Retail Partner
    </div>,
    <div>
      Launched <span className="text-blue">Harry Potter</span> IP with Warner Bros
    </div>,
    <div>
      Expanded to <span className="text-blue">China</span> Market
    </div>,
    <div>
      Got successfully <span className="text-blue">Acquired</span>
    </div>,
  ],
  testimonial: {
    quote:
      'Tintash team has great communication skills and attention to detail, and are firmly committed to their delivery goals. We had a very positive work experience and a very positive relationship.',
    name: 'Patrick Cosgrove',
    position: <div>CTO, Lifeprint</div>,
    image: require('../../../assets/images/landing/lifeprint/lifeprint-client.webp').default,
  },
};

function Index() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landing | Lifeprint</title>
      </Helmet>
      <div className="landing-wrapper">
        <LandingHeader />
        <HeroSection title={Hero.title} bannerSrc={Hero.bannerSrc} logoSrc={Hero.logoSrc} />
        <TestimonialSection
          details={Testimonial.details}
          testimonial={Testimonial.testimonial}
          themeColor={themeColor}
        />
        <img src={LifeprintImage} alt="lifeprint" />
        <PartnershipSection />
        <AboutSection />
        <ClientLogosSection />
        <LandingFooter />
      </div>
    </>
  );
}

export default Index;

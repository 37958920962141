import React from 'react';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {Event} from '../../../GAClickEvent';

import LifeprintOne from '../../../../assets/images/landing/lifeprint/lifeprint-1.webp';
import LifeprintTwo from '../../../../assets/images/landing/lifeprint/lifeprint-2.webp';
import LifeprintThree from '../../../../assets/images/landing/lifeprint/lifeprint-3.webp';
import LifeprintFour from '../../../../assets/images/landing/lifeprint/lifeprint-4.webp';

import * as classes from './Partnership.module.css';

const PartnershipSection = () => (
  <div className={classes.wrapper}>
    <div className={clsx(classes.container, 'flex-column')}>
      <h1 className={clsx(classes.sectionTitle, 'mb-3')}>Business Problem</h1>
      <p className={clsx(classes.description, 'mb-3')}>
        Lifeprint envisioned an app-controlled AR photo printer to revive the essence of printed
        memories in a digital era dominated by Facebook, Instagram, and Snapchat. The start-up at
        the time needed to crowdfund their idea and was looking for a development partner to help
        bring their vision to life.
      </p>
    </div>
    <div className={clsx(classes.container, 'flex-column')}>
      <h1 className={clsx(classes.sectionTitle, 'mb-3')}>Lifeprint and Tintash Partnership</h1>
      <p className={clsx(classes.description, 'mb-3')}>
        Tintash was the development partner of choice of Lifeprint for over 3 years. We developed
        the native iOS and Android apps with printing (Bluetooth & WiFi) and scanning (AR
        experience) modules and a globally supported cloud infrastructure.
      </p>
    </div>
    <div className={clsx(classes.container, 'flex-column-reverse flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}>Fundraising Success</h2>
        <p className={clsx(classes.description, 'mb-3')}>
          Lifeprint’s journey with Tintash started by building out a successful POC for their idea,
          enabling Lifeprint to successfully raise $300k+ in seed funding through Kickstarter and
          Indiegogo campaigns.
        </p>
        <p className={classes.description}>
          This capital fuelled the manufacturing of printers and sustained ongoing product
          development.
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={LifeprintOne} alt="liferint" />
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={LifeprintTwo} alt="liferint" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}> Becoming Apple’s Global Retail Partner</h2>
        <p className={clsx(classes.description, 'mb-3')}>
          Following the successful fundraising campaign, Lifeprint landed an opportunity to demo the
          product to Apple.
        </p>
        <p className={classes.description}>
          We enhanced app scalability for reliable printing and scanning, and created the Lifeprint
          social network within the app where users could share, like, and comment on content,
          leading to global availability and partnership with Apple.
        </p>
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column-reverse flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}> China Market Expansion</h2>
        <p className={classes.description}>
          Becoming Apple’s retail partner meant reaching far beyond borders - including the vast
          China market. However, the country’s firewall policy posed a new technical challenge. We
          addressed this by creating a globally scalable and maintainable infrastructure, using a
          single code for multiple deployment environments.
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={LifeprintThree} alt="liferint" />
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={LifeprintFour} alt="liferint" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}>Harry Potter IP Launch & Successful Acquisition</h2>

        <p className={clsx(classes.description, 'mb-3')}>
          Soon after, Lifeprint bagged the lucrative Harry Potter license with Warner Bros and
          launched their latest product, the Harry Potter Magic Photo and Video Printer.
        </p>
        <p className={classes.description}>
          Efforts of the Tintash team throughout the course helped Lifeprint grow, eventually
          leading to a successful acquisition.
        </p>
      </div>
    </div>
    <div className={clsx(classes.buttonContainerMain)}>
      <div className={classes.buttonContainer}>
        <Link
          onClick={() =>
            Event('Case Study', 'Case Study Button is clicked', 'Lifeprint Landing Page')
          }
          to="/portfolio/lifeprint"
        >
          <button type="button" className={classes.button}>
            Read Full Case Study
          </button>
        </Link>
      </div>

      <div className={classes.buttonContainer}>
        <Link
          onClick={() =>
            Event('Portfolio', 'Portfolio button is clicked', 'Lifeprint Landing Page')
          }
          to="/portfolio"
        >
          <button type="button" className={classes.button2}>
            Explore Our Portfolio
          </button>
        </Link>
      </div>
    </div>
  </div>
);

export default PartnershipSection;
